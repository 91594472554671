import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { makeStyles, Grid, Container } from '@material-ui/core'
// import Pager from '../components/pagerServices'
import Master from '../components/Master'
// import Services from '../components/ListView/Services/Services'

const ServicesPaginate = ({ data, pageContext }) => {
    // const services = data.allService.nodes
    const page = data.allPage.nodes[0]

    const useStyles = makeStyles(theme => ({
        center: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
        content: {
            padding: theme.spacing(0, 3, 6),
        },
        imagePaper: {
            width: '100%',
            marginTop: '10px',
            borderRadius: '4px'
        },
    }));

    const classes = useStyles();

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/servicos`}
                noindex={false}
                nofollow={false}
                openGraph={{
                    url: `${process.env.SITE_URL}/servicos`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Container maxWidth="md" component="main" className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.center}>{page.title}</h1>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div dangerouslySetInnerHTML={{ __html: page.details }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <img className={classes.imagePaper} src={`https://firebasestorage.googleapis.com/v0/b/indall.appspot.com/o/images%2Flayout%2Fservicos.jpg?alt=media&token=b6efcbc0-24db-4d1d-a515-e43bf2f1b8bd`} title={page.title} alt={page.title} />
                    </Grid>
                </Grid>
                {/* <Pager pageContext={pageContext} />
            <Services services={services} />
            <Pager pageContext={pageContext} /> */}
            </Container>
        </Master>
    )
}

export const query = graphql`
    query($skip: Int!, $limit:Int!){
        allService(
            sort: {fields:updatedAt, order: DESC}
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                title
                slug
                description
                image
                imageThumbnail
            }
        }
        allPage(filter: {id: {eq: "5efa29ed226aed4281876a6b"}}) {
            nodes {
                title
                description
                keywords
                details
            }
        }
    }
`;

export default ServicesPaginate